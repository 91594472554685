<!-- 
  author: 张洪志
  description: 全部主题基金
 -->
<template>
  <section class="theme-fund-list">
    <fund-nav />
    <site-nav>
      <span>&gt;</span>
      <RouterLink :to="{name:'FundMarket'}">基金超市</RouterLink>
      <span>&gt;</span>
      <RouterLink :to="{name:'TopicFund'}">主题基金</RouterLink>
      <span>&gt;</span>
      <span>全部主题基金</span>
    </site-nav>
    <div class="fund-order vw">
      <div class="sec-title flex-between">
        <p class="title">
          <span class="iconfont icon-a-fundmarket-theme orange"></span>
          <span class="title-txt">热门主题</span>
        </p>
        <p class="search-box flex-between">
          <span class="ico-search"></span>
          <input class="txt" type="text" v-model="searchKey" placeholder="基金简称/代码/关键字">
          <input class="btn" type="button" value="搜索" @click="searchFundSort()">
        </p>
      </div>
      <div :class="['flex-between fund-cat-sec']">
        <div class="fund-cat-box flex-between">
          <div :class="['fund-cat-all']" @click="getFundSort()">
            <p>全部</p>
          </div>
          <div :class="{'current': fundOrderCat===item.code}" v-for="item in fundOrderCats" :key="item.code" @click="getFundSort(item.code)">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="flex-between fund-order-title fund-order-list">
        <span>基金代码</span>
        <span class="name">基金名称</span>
        <span>风险等级</span>
        <span class="flex-between">
          <span>最新净值</span>
          <i :class="['btn', fundSortStyle('newestValue')]" @click="sortFund('newestValue')"></i>
        </span>
        <span class="flex-between">
          <span>日涨跌幅</span>
          <i :class="['btn', fundSortStyle('changeDay')]" @click="sortFund('changeDay')"></i>
        </span>
        <span class="flex-between">
          <span>
            近一个月<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeMonth')]" @click="sortFund('changeMonth')"></i>
        </span>
        <span class="flex-between">
          <span>
            近三个月<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeThreeMonth')]" @click="sortFund('changeThreeMonth')"></i>
        </span>
        <span class="flex-between">
          <span>
            近半年<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeHalfYear')]" @click="sortFund('changeHalfYear')"></i>
        </span>
        <span class="flex-between">
          <span>
            近一年<br>涨跌幅 
          </span>
          <i :class="['btn', fundSortStyle('changeYear')]" @click="sortFund('changeYear')"></i>
        </span>
        <span v-if="false">买入费率</span>
        <span class="action">操作</span>
      </div>
      <div class="flex-between dark fund-order-content fund-order-list" v-for="item in fundOrderData" :key="item.fundCode">
        <span class="">{{ item.fundCode }}</span>
        <router-link class="name" :to="{name:'FundDetail',params:{fundCode:item.fundCode,fundType:item.ofundType}}">{{ item.fundName}}</router-link>
        <span :class="item.ofundRisklevel > 3 ? 'red' : item.ofundRisklevel > 1 ? 'orange' : ''">{{ item.risklevel }}</span>
        <span class="value dark">
          <span>{{ item.newestValue }}</span>
          <br>
          <span class="gray">{{ item.enddate }}</span>
        </span>
        <span :class="formatRiseStyle(item.changeDay)">{{ item.changeDay }}%</span>
        <span :class="formatRiseStyle(item.changeMonth)">{{ item.changeMonth }}%</span>
        <span :class="formatRiseStyle(item.changeThreeMonth)">{{ item.changeThreeMonth }}%</span>
        <span :class="formatRiseStyle(item.changeHalfYear)">{{ item.changeHalfYear }}%</span>
        <span :class="formatRiseStyle(item.changeYear)">{{ item.changeYear }}%</span>
        <span :class="item.saleFeeRate.indexOf('%') > -1 ? 'orange' : 'gray'" v-if="false">{{ item.saleFeeRate}}</span>
        <span class="action flex-between">
          <input type="button" :value="item.buyTxt" :class="['btn btn-orange btn-buy', {'disable stop-buy':item.buyStatus === '0'}]" @click="buyFund(item,1)">
          <input type="button" value="定投" :class="['btn btn-orange-plain btn-buy', {'disable stop-buy':item.buyStatus === '0'}]" @click="buyFund(item,2)">
        </span>
      </div>
    </div>
    <div class="page-bar vw">
      <page-bar :total="pageTotal" :display="5" @click="goPage" />
    </div>
    <DinDetails :showmodel="showBuyConfirm" @cancels="showBuyConfirm=false" @showdelte="showBuyConfirm=false" @confirms="buyConfirm">
      {{buyConfirmTxt}}
    </DinDetails>
  </section>
</template>

<script>  
  import FundNav from '@/views/fund-market/components/FundNav.vue'
  import SiteNav from '@/views/fund-market/components/SiteNav.vue'
  import PageBar from '@/components/Pagebar.vue'
  import DinDetails from '@/components/Models.vue'
  import {fundList,themeList} from '@/api/fund.js'
  import {getDicts} from '@/api/dict.js'
  export default {
    name: 'TopicFunds',
    components: {
      FundNav,
      SiteNav,
      PageBar,
      DinDetails
    },
    data() {
      return {
        fundOrderCats: [],
        fundOrderCat: '',
        fundOrderType: {},
        fundOrderData: [],
        riskLevels: [],
        pageTotal: 0,
        pageNum: 1,
        searchKey: '',
        fundCode: '',
        fundName: '',
        
        showBuyConfirm: false,
        buyConfirmTxt: '',
        buyConfirmStep: 0,
        preBuyFund:null,
        buyAction: ''
      }
    },
    created() {
      this.fundOrderCat = this.$route.params.themeId
      this.getFundType()
      this.getRiskLevel().then(() => {
        let key = (this.$route.params.key||'').trim()
        this.searchKey = key
        if(key || key === 0) {
          this.searchFundSort()
        } else {
          this.getFundSort(this.fundOrderCat)
        }
        this.sortFund('changeDay')
      })
    },
    methods: {
      /** 购买基金 */
      buyFund(fund,action) {
        if(fund.buyStatus === '0') {
          return;
        }
        this.buyConfirmStep = 0
        let token = this.$store.getters.token;
        let _userInfo = localStorage.getItem("customerInfo");
        if (!token || !_userInfo) {
          this.buyConfirmTxt = '请登录!'
          this.buyConfirmStep = 1
        }else {
          let userInfo = JSON.parse(_userInfo);
          if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
            this.buyConfirmTxt = '请先开户!'
            this.buyConfirmStep = 2
          }else if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
            this.buyConfirmTxt = '请补充资料!'
            this.buyConfirmStep = 3
          }else if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
            this.buyConfirmTxt = '请设置交易密码!'
            this.buyConfirmStep = 4
          }else if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
            this.buyConfirmTxt = '请先完成风险测评!'
            this.buyConfirmStep = 5
          }else if(userInfo.riskLevel < fund.ofundRisklevel){
            this.buyConfirmTxt = '当前产品风险等级高于您的风险测评等级，确认购买？'
            this.buyConfirmStep = 6
          }
        }
        this.preBuyFund = fund
        this.buyAction = action
        if(!this.buyConfirmStep) {
          this.buyConfirm()
          return
        }
        this.showBuyConfirm = true
      },
      /** 购买基金确认 */
      buyConfirm() {
        this.showBuyConfirm = false
        if(this.buyConfirmStep === 1) { 
          this.$router.push({ name: "Login" })
          return
        }
        if(this.buyConfirmStep === 2) { 
          this.$router.push({ name: "OpenAccount" });
          return
        }
        if(this.buyConfirmStep === 3) { 
          this.$router.push({ name: "PersonalDataSupply" });
          return
        }
        if(this.buyConfirmStep === 4) { 
          this.$router.push({ name: "OpenAccount" });
          return
        }
        if(this.buyConfirmStep === 5) { 
          this.$router.push({ name: "RiskAssessment" });
          return
        }
        let actionName = this.buyAction === 1 ? 'ProcessPurchase' : 'AddProcess'
        this.$router.push({name:actionName,params:{fundCode:this.preBuyFund.fundCode,newfund:this.preBuyFund.newfund}})
      },
      /** 获取基金主题 */
      getFundType() {
        //todo: 这个接口需要换,这里需要查询所有主题,暂时取的100个
        themeList(this.pageNum,100,'1','1').then(result => {
          if(!result.data) {
            return
          }
          this.fundOrderCats = Array.from(result.data.data, m => ({name:m.themeName,code:m.themeId}))
        })
      },
      /** 获取基金风险级别 */
      getRiskLevel() {
        return getDicts('sys_fund_risklevel').then(result => {
          if(!result.data) {
            return
          }
          this.riskLevel = Array.from(result.data, m => ({name:m.dictLabel,code:m.dictValue}))
        })
      },
      /** 基金排序箭头样式 */
      fundSortStyle(key) {
        return this.fundOrderType[key] === '-1' ? 'ico-sort-down' : this.fundOrderType[key] === '1' ? 'ico-sort-up' : 'ico-sort'
      },
      /** 基金排序 */
      sortFund(key) {
        let order = this.fundOrderType[key]
        order = !order ? '-1' : order === '-1' ? '1' : ''
        this.fundOrderType = {}
        this.fundOrderType[key] = order
        this.getFundSort(this.fundOrderCat)
      },
      /** 获取基金排行 */
      getFundSort(cat) {
        this.fundOrderCat = cat
        let params = {
          pageSize:10,
          pageNum:this.pageNum,
          privateFlag: '0',
          customerAppTheme: this.fundOrderCat,
          incomeYear: '-1',
          fundName: this.fundName,
          fundCode: this.fundCode
        }
        let _params = {...params,...this.fundOrderType}
        fundList(_params).then(result => {
          let data = result.data.data
          data.forEach(m => {
            m.risklevel = ((this.riskLevel.find(n => m.ofundRisklevel === n.code)||{}).name||'').replace('风险','')
            m.ofundRisklevel = parseInt(m.ofundRisklevel)
            m.newestValue = m.newestValue.$trimZero()
            m.changeDay = (parseFloat(m.changeDay)>0?'+':'')+m.changeDay.$trimZero(2)
            m.changeMonth = (parseFloat(m.changeMonth)>0?'+':'')+m.changeMonth.$trimZero(2)
            m.changeThreeMonth = (parseFloat(m.changeThreeMonth)>0?'+':'')+m.changeThreeMonth.$trimZero(2)
            m.changeHalfYear = (parseFloat(m.changeHalfYear)>0?'+':'')+m.changeHalfYear.$trimZero(2)
            m.changeYear = (parseFloat(m.changeYear)>0?'+':'')+m.changeYear.$trimZero(2)
            m.saleFeeRate = m.saleFeeRate ? m.saleFeeRate + '%' : '免手续费'
            m.buyTxt = m.fundStatus === '1' ? '认购' : '买入'
            m.newfund = m.fundStatus === '1' ? '1' : ''
          })
          this.fundOrderData = data
          this.pageTotal = result.data.pages
        })
      },
      /** 格式化基金涨跌样式
       * @param {Number|String} priceRate 涨跌幅
       */
      formatRiseStyle(priceRate) {
        priceRate = priceRate || 0
        priceRate = parseFloat(priceRate)
        return priceRate > 0 ? 'red' : priceRate < 0 ? 'green' : ''
      },
      /** 页跳转 */
      goPage(page) {
        this.pageNum = page
        this.getFundSort(this.fundOrderCat)
      },
      /** 搜索基金 */
      searchFundSort() {
        this.fundCode = ''
        this.fundName = ''
        let key = this.searchKey.trim()
        if(!key && key !== 0) {
          key = ''
        }
        if(/^\d{6}$/.test(key)) {
          this.fundCode = key
        } else {
          this.fundName = key
        }
        this.getFundSort(this.fundOrderCat)
      }
    }
  }
</script>

<style lang="less" scoped>
  @orange:#CE9B63;
  @gray:#999;
  @red:#D43F3F;
  @green: #00aa00;
  .orange{color:@orange;}
  .gray{color:@gray;}
  .red{ color:@red;}
  .green{ color:@green;}
  .small{font-size: 12px;}
.btn-orange { background: #deb87b; color:#fff; border-radius: 3px;}
.btn-orange-plain{ border: 1px solid #deb87b; background: #fff; color: #deb87b; border-radius: 3px;}
  
.theme-fund-list{background: #F3F4F6;}
.fund-order{ padding: 20px 0;
  .sec-title{ align-items:flex-start;
    .title{font:bold 20px "microsoft yahei";
      .iconfont{ margin-right:10px; font-size: 20px;}
    }
    .search-box{align-items: center; background: #eee; padding:0 15px; border-radius: 3px;
      .txt{width:290px; height:1em; line-height: 1em; padding:10px; background: none;}
      .btn{ color:@orange;}
    }
  }
  .fund-cat-box{ width: 1080px; flex-wrap: wrap; justify-content: flex-start;}
  .fund-cat-all,
  .fund-cat-box>div{color: #666; padding:5px 20px; text-align: center; position: relative; height: 1.5em; cursor: pointer; user-select: none; border-radius: 1em 0;
  }
  .fund-cat-all{color:@orange !important; background: #fff; font:bold 15px "microsoft yahei"}
  .fund-cat-box>div { margin: 0em 1.5em 0.5em 0; }
  .show-less{ height:3em;}
  .fund-cat-sec { padding:30px 0;
    div.current{ border-color: #deb87b; background: #deb87b; color: #fff;}
  }
  .fund-order-list{ text-align: center;}
  .fund-order-title{ background: #F9F9FA; padding: 1em 0; align-items: center;}
  .fund-order-title .btn{ font-size: 1.2em; margin: 0px 0 0 2px;}
  .fund-order-title>span{ justify-content: center; align-items: center;}
  .fund-order-list>span{ width: 7em;}
  .fund-order-list .name{ text-align: left; width:200px;}
  .fund-order-list .action {width:170px;}
  .fund-order-content { padding: 1.8em 0;  line-height: 2em; border-bottom: 1px solid #eee;
    .value{ line-height: 1.5em;}
    .dark{ color: #5d5d5d;}
    .btn-buy{ padding: 10px 23px; height:2.5em;
    }
  }
}
.page-bar{ text-align: right; background: #fff; padding:1em 0;}
</style>
